import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ecdc017"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "m-card-result-root"
}
const _hoisted_2 = ["data-point"]
const _hoisted_3 = { class: "cards" }
const _hoisted_4 = { class: "card3" }
const _hoisted_5 = { class: "card2" }
const _hoisted_6 = { class: "card1" }
const _hoisted_7 = ["data-point"]
const _hoisted_8 = { class: "cards" }
const _hoisted_9 = { class: "card3" }
const _hoisted_10 = { class: "card1" }
const _hoisted_11 = { class: "card2" }
const _hoisted_12 = ["data-color"]
const _hoisted_13 = {
  key: 1,
  class: "m-card-result-root"
}
const _hoisted_14 = ["data-point"]
const _hoisted_15 = { class: "cards" }
const _hoisted_16 = { class: "card1" }
const _hoisted_17 = ["data-point"]
const _hoisted_18 = { class: "cards" }
const _hoisted_19 = { class: "card1" }
const _hoisted_20 = ["data-color"]
const _hoisted_21 = {
  key: 2,
  class: "m-card-result-root"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.gameType === 1)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["player-result-group", { flashing: _ctx.winner === 3 }])
        }, [
          _createElementVNode("div", {
            class: "cards-point-and-label",
            "data-point": _ctx.blueTotal
          }, _toDisplayString(_ctx.t('baccarat.player')), 9, _hoisted_2),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_4, [
                (_ctx.blueThirdCard !== '')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(_ctx.blueThirdCard)
                    }, null, 2))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_5, [
                (_ctx.blueSecondCard !== '')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(_ctx.blueSecondCard)
                    }, null, 2))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_6, [
                (_ctx.blueFirstCard !== '')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(_ctx.blueFirstCard)
                    }, null, 2))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["banker-result-group", { flashing: _ctx.winner === 1 }])
        }, [
          _createElementVNode("div", {
            class: "cards-point-and-label",
            "data-point": _ctx.redTotal
          }, _toDisplayString(_ctx.t('baccarat.banker')), 9, _hoisted_7),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_9, [
                (_ctx.redThirdCard !== '')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(_ctx.redThirdCard)
                    }, null, 2))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_10, [
                (_ctx.redFirstCard !== '')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(_ctx.redFirstCard)
                    }, null, 2))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_11, [
                (_ctx.redSecondCard !== '')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(_ctx.redSecondCard)
                    }, null, 2))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ], 2),
        _createElementVNode("div", {
          class: "result",
          "data-color": _ctx.winner
        }, _toDisplayString(_ctx.resultLabel), 9, _hoisted_12)
      ]))
    : (_ctx.gameType === 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("div", {
            class: _normalizeClass(["dragon-result-group", { flashing: _ctx.winner === 1 || _ctx.winner === 4 }])
          }, [
            _createElementVNode("div", {
              class: "cards-point-and-label",
              "data-point": _ctx.redTotal
            }, _toDisplayString(_ctx.t('dragontiger.dragon')), 9, _hoisted_14),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_16, [
                  (_ctx.redFirstCard !== '')
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.redFirstCard)
                      }, null, 2))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["tiger-result-group", { flashing: _ctx.winner === 3 || _ctx.winner === 6 }])
          }, [
            _createElementVNode("div", {
              class: "cards-point-and-label",
              "data-point": _ctx.blueTotal
            }, _toDisplayString(_ctx.t('dragontiger.tiger')), 9, _hoisted_17),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_19, [
                  (_ctx.blueFirstCard !== '')
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.blueFirstCard)
                      }, null, 2))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ], 2),
          _createElementVNode("div", {
            class: "result",
            "data-color": _ctx.winner
          }, _toDisplayString(_ctx.resultLabel), 9, _hoisted_20)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_21))
}