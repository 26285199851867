export function getCardFaceValue(code: string): number {
    if (code.length !== 4) return 0
    return parseInt(code.substr(1, 2))
}

export function getBaccaratValue(code: string): number {
    if (code.length !== 4) return 0

    const value = parseInt(code.substr(1, 2))

    if (value >= 10) return 0
    return value
}

/**
 * Get Which Card has a higher value when they have the same card face value
 * @param code
 */
export function getDTFaceValue(code: string): number {
    if (code.length !== 4) return 0
    return parseInt(code.substr(1, 2))
}

export function getColorValue(code: string): number {
    if (code.length !== 4) return 0
    return parseInt(code.substr(0, 1))
}

export function findIndicesForSumMod10(array: number[]) {
    const combinations = []

    for (let i = 0; i < array.length; i++) {
        for (let j = i + 1; j < array.length; j++) {
            for (let k = j + 1; k < array.length; k++) {
                combinations.push([i, j, k])
            }
        }
    }

    let combination: number[] = []

    for (let x = 0; x < combinations.length; x++) {
        const p = combinations[x]

        if ((array[p[0]] + array[p[1]] + array[p[2]]) % 10 === 0) {
            combination = p
            break
        }
    }

    return combination
}
