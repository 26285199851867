import {
    getBaccaratValue,
    getCardFaceValue,
    getColorValue,
    getDTFaceValue
} from '@/utils/cards'
import { GameRoundState, RESULT } from './global'

export class GameResult {
    result!: number
    gameType: number
    rawData: string
    state!: number
    isNewShoe!: boolean
    whoWin!: number
    bluePair = false
    redpair = false
    won = 0
    redTotal = 0
    blueTotal = 0
    redCard = new Array<string>()
    blueCard = new Array<string>()
    hasCardToDisplay = false
    isSuper6 = false

    constructor(str: string, gameType: number) {
        this.gameType = gameType
        this.rawData = str

        const keyValues = str.split('&')

        keyValues.forEach((item) => {
            if (item.startsWith('result=')) {
                this.result = item.split('=')[1] === 'ok' ? 0 : -1
            } else if (item.startsWith('state_id=')) {
                this.state = parseInt(item.split('=')[1])
            } else if (item.startsWith('show=')) {
                this.isNewShoe = item.split('=')[1] === '1'
            } else if (item.startsWith('whowin=')) {
                this.whoWin = parseInt(item.split('=')[1])
            } else if (
                item.startsWith('xdwin=') &&
                this.state === GameRoundState.END_OF_ROUND
            ) {
                this.bluePair = parseInt(item.split('=')[1]) === 1
            } else if (
                item.startsWith('zdwin=') &&
                this.state === GameRoundState.END_OF_ROUND
            ) {
                this.redpair = parseInt(item.split('=')[1]) === 1
            } else if (
                item.startsWith('oldwin=') &&
                this.state === GameRoundState.END_OF_ROUND
            ) {
                // console.log(str)
                const strCards = item.split('=')[1]
                // if (strCards.split('#').length > 1) {
                //     this.hasCardToDisplay = true
                //     console.log(strCards.split('#').length)
                // } else {
                //     this.hasCardToDisplay = false
                // }
                if (gameType === 1) {
                    // parse baccarat result
                    const cards = strCards.split('#')
                    const bankerHand = cards[1].split('$')
                    const playerHand = cards[0].split('$')
                    this.blueCard = playerHand
                    this.redCard = bankerHand
                    const bankerValue = new Array<number>()
                    const bankerFaceValue = new Array<number>()
                    let bankerTOtal = 0
                    bankerHand.forEach((card) => {
                        const tempValue = getBaccaratValue(card)
                        bankerValue.push(tempValue)
                        bankerFaceValue.push(getCardFaceValue(card))
                        bankerTOtal += tempValue
                    })
                    const playerValue = new Array<number>()
                    const playerFaceValue = new Array<number>()
                    let playerTotal = 0
                    playerHand.forEach((card) => {
                        const tempValue = getBaccaratValue(card)
                        playerValue.push(tempValue)
                        playerFaceValue.push(getCardFaceValue(card))
                        playerTotal += tempValue
                    })
                    this.bluePair = playerFaceValue[0] === playerFaceValue[1]
                    this.redpair = bankerFaceValue[0] === bankerFaceValue[1]
                    bankerTOtal = bankerTOtal % 10
                    playerTotal = playerTotal % 10

                    this.redTotal = bankerTOtal
                    this.blueTotal = playerTotal

                    if (bankerTOtal === playerTotal) {
                        this.whoWin = RESULT.GREEN
                    } else if (bankerTOtal > playerTotal) {
                        this.whoWin = RESULT.RED
                    } else if (playerTotal > bankerTOtal) {
                        this.whoWin = RESULT.BLUE
                    }

                    if (this.redCard[0] === '' || this.blueCard[0] === '') {
                        this.hasCardToDisplay = false
                    } else {
                        this.hasCardToDisplay = true
                    }
                } else {
                    // parse dragon tiger result
                    const cards = strCards.split('#')
                    const redHand = cards[1]
                    const blueHand = cards[0]
                    this.redCard.push(redHand)
                    this.blueCard.push(blueHand)
                    const redTotal = getCardFaceValue(redHand)
                    const blueTotal = getCardFaceValue(blueHand)

                    this.redTotal = redTotal
                    this.blueTotal = blueTotal

                    if (redTotal === blueTotal) {
                        // check dt face value
                        const redFValue = getColorValue(redHand)
                        const blueFValue = getColorValue(blueHand)
                        if (redFValue === blueFValue) {
                            this.whoWin = RESULT.GREEN
                        } else if (redFValue > blueFValue) {
                            this.whoWin = RESULT.TIGER
                        } else if (blueFValue > redFValue) {
                            this.whoWin = RESULT.DRAGON
                        }
                    } else if (redTotal > blueTotal) {
                        this.whoWin = RESULT.DRAGON
                    } else if (blueTotal > redTotal) {
                        this.whoWin = RESULT.TIGER
                    }

                    if (this.redCard[0] === '' || this.blueCard[0] === '') {
                        this.hasCardToDisplay = false
                    } else {
                        this.hasCardToDisplay = true
                    }
                }
            } else if (item.startsWith('won=')) {
                const w = parseInt(item.split('=')[1])
                if (w === -1 || w === -2) {
                    this.won = 0
                } else {
                    this.won = w
                }
            } else if (item.startsWith('sswin=')) {
                const s6 = parseInt(item.split('=')[1])

                this.isSuper6 = s6 === 1
            }
        })
    }
}
