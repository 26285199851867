
import { GameResult } from '@/types/GameResult'
import { EventBuss } from '@/types/global'
import {
    Emitter,
    PropType,
    computed,
    defineComponent,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    ref,
    watch
} from 'vue'
import { useI18n } from 'vue-i18n'
export default defineComponent({
    emits: ['show-result'],
    props: {
        gameType: {
            required: true,
            type: Number as PropType<number>
        }
    },
    setup(props, { emit }) {
        const emitter = inject('emitter') as Emitter
        const { t } = useI18n()
        const redTotal = ref<number>(0)
        const blueTotal = ref<number>(0)
        const winner = ref<number>(-1)

        const blueHand = ref<string[]>([])
        const redHand = ref<string[]>([])
        const resultLabel = ref<string>('')

        onBeforeMount(() => {
            emitter.on(EventBuss.SHOW_GAME_RESULT, handleShowGameResult)
        })

        onBeforeUnmount(() => {
            emitter.off(EventBuss.SHOW_GAME_RESULT, handleShowGameResult)
        })

        const handleShowGameResult = (_gameResult: GameResult) => {
            if (_gameResult) {
                redTotal.value = _gameResult.redTotal
                blueTotal.value = _gameResult.blueTotal
                winner.value = _gameResult.whoWin
                blueHand.value = _gameResult.blueCard
                redHand.value = _gameResult.redCard

                const g: number[] = []

                if (winner.value === 1 && props.gameType === 1) {
                    resultLabel.value = t('baccarat.bankerwin')
                    g.push(1)
                } else if (winner.value === 4 && props.gameType === 2) {
                    resultLabel.value = t('dragontiger.dragonwin')
                    g.push(4)
                } else if (winner.value === 2 || winner.value === 5) {
                    resultLabel.value = t('baccarat.tie')
                    g.push(5)
                } else if (winner.value === 3 && props.gameType === 1) {
                    resultLabel.value = t('baccarat.playerwin')
                    g.push(3)
                } else if (winner.value === 6 && props.gameType === 2) {
                    resultLabel.value = t('dragontiger.tigerwin')
                    g.push(6)
                }

                if (_gameResult.bluePair) g.push(8)
                if (_gameResult.redpair) g.push(7)

                emit('show-result', g)
            }
        }

        const redFirstCard = computed(() => {
            if (redHand.value.length > 0) {
                return `mv_${redHand.value[0]}`
            }
            return ''
        })

        const redSecondCard = computed(() => {
            if (redHand.value.length > 1) {
                return `mv_${redHand.value[1]}`
            }
            return ''
        })

        const redThirdCard = computed(() => {
            if (redHand.value.length > 2) {
                return `mh_${redHand.value[2]}`
            }
            return ''
        })

        const blueFirstCard = computed(() => {
            if (blueHand.value.length > 0) {
                return `mv_${blueHand.value[0]}`
            }
            return ''
        })

        const blueSecondCard = computed(() => {
            if (blueHand.value.length > 1) {
                return `mv_${blueHand.value[1]}`
            }
            return ''
        })

        const blueThirdCard = computed(() => {
            if (blueHand.value.length > 2) {
                return `mh_${blueHand.value[2]}`
            }
            return ''
        })

        return {
            t,
            blueTotal,
            redTotal,
            winner,
            redFirstCard,
            blueFirstCard,
            redSecondCard,
            blueSecondCard,
            blueThirdCard,
            redThirdCard,
            resultLabel
        }
    }
})
